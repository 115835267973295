<template>
  <div>
    Panel
  </div>
</template>

<script>


export default {
  name: "PanelView",
};
</script>